import { AfterContentChecked, Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as myGlobals from 'Globals';
import { NavbarService } from '../navbarservice.service';
import { Meta } from '@angular/platform-browser';

declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-main-portfolio',
  templateUrl: './main-portfolio.component.html',
  styleUrls: ['./main-portfolio.component.css']
})
export class MainPortfolioComponent implements OnInit {

  username;
  mobile;
  isAtHome;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.username = "cosmicetoro";
    this.getLiveRiskScore();
    this.getLivePortfolio();
    // if(this.theReload = true){}
    // this.loadFeed();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;;
    }

    if(window.location.pathname == '/' || window.location.pathname == '/user'){
      this.isAtHome = true;
    }else{
      this.isAtHome = false;
    }
  }

  instruments = [];
  instrumentIds = [];
  instrumentIdVal = [];

  riskId = [];
  namesRequest = [];
  nameUrl = "";

  nameSet = [];
  nameVals = [];

  riskNames = [];
  portNames = [];

  async getLiveRiskScore() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/RiskScore/Live/', { headers }).subscribe(data => {
      this.instruments = data;
      //console.log(this.instruments);
      this.instrumentIds = this.instruments["contributers"];
      //console.log("this.instrumentIds - user set",this.instrumentIds);
      // this.instrumentIds has all details for risk
      this.instrumentIds.forEach(i => {
        this.instrumentIdVal.push(i['instrumentId']);
      });

      for (var i = 0; i <= this.instrumentIdVal.length - 1; i++) {
        if (i == this.instrumentIdVal.length - 1) {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i]);
        } else {
          this.namesRequest.push("InstrumentIds=" + this.instrumentIdVal[i] + "&");
        }
      }

      this.nameUrl = this.namesRequest.toString();
      var reqUrl = this.nameUrl.replace(/,/g, '');


      //console.log(reqUrl);

      const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
      this.http.get<any>('https://api.etoro.com/Metadata/V1/Instruments?' + reqUrl, { headers }).subscribe(data => {
        // console.log("metadata set",data)
        this.nameSet = data;
        this.nameSet.forEach(i => {
          this.nameVals.push(i["name"]);
        })
        // console.log("names from metadata", this.nameVals);
        this.nameSet.forEach(i => {
          this.riskId.push(i["instrumentId"]);
        })

        this.riskId.sort((a, b) => a - b);
        this.instrumentIdVal.sort((a, b) => a - b);
        this.instrumentIds.sort((a, b) => a - b)
        //console.log(this.instrumentIdVal);

        for (var i = 0; i <= this.riskId.length - 1; i++) {
          //console.log("test")
          this.instrumentIds[i].instrumentId = this.nameSet[i].name;
          this.portfolioPositions[i].positionId = this.nameVals[i];
        }

      });



      // console.log("this.riskId - metadata set",this.riskId);
      // console.log("instrumentIdVal - user set", this.instrumentIdVal);
      // console.log(this.riskNames);



    })

  }

  portfolioSet = [];
  portfolioPositions = [];
  totalInvested = 10000;
  investmentPerInstrument = 0;
  valueGained = 0;
  lossOrGain = false;
  negOrPos = false;
  async getLivePortfolio() {
    const headers = { 'Ocp-Apim-Subscription-Key': '4e85f6e1911b41a385861c90b2444504' }
    await this.http.get<any>('https://api.etoro.com/API/User/V1/' + this.username + '/Portfolio/Live', { headers }).subscribe(data => {

      // console.log(data);
      this.portfolioSet = data;
      //console.log(this.portfolioSet)
      this.portfolioPositions = this.portfolioSet["positions"];

      this.portfolioPositions.sort((a, b) => a - b);

      this.investmentPerInstrument = this.totalInvested / this.portfolioPositions.length;

      let decimalised = 0;

      for(let i = 0; i<= this.portfolioPositions.length - 1; i++){
        this.portfolioPositions[i].invested = this.investmentPerInstrument;
        decimalised = this.portfolioPositions[i].netProfit / 100;
        this.valueGained = this.investmentPerInstrument * (1 + decimalised) - this.investmentPerInstrument;
        if(this.valueGained < 0){
          this.lossOrGain = false;
          this.valueGained *= -1;
        }else{
          this.lossOrGain = true;
        }
        this.portfolioPositions[i].profitloss = this.valueGained.toFixed(2);
        this.portfolioPositions[i].lossOrGain = this.lossOrGain;
        this.portfolioPositions[i].investmentPct = this.portfolioPositions[i].investmentPct.toFixed(1)
        this.valueGained = 0;
        decimalised = 0;

        if(this.portfolioPositions[i].netProfit < 0){
          this.negOrPos = false;
        }else{
          this.negOrPos = true;
        }

        this.portfolioPositions[i].negOrPos = this.negOrPos;
        this.portfolioPositions[i].netProfit = this.portfolioPositions[i].netProfit.toFixed(2);
        this.portfolioPositions[i].openRate = this.portfolioPositions[i].openRate.toFixed(2);
      }

      // console.log(this.portfolioPositions);
      //var test = this.nameVals;
      // console.log(test);
      //var max = this.portfolioPositions.length;
      // console.log(max);
      // this.portfolioPositions[1].positionId = "hello";
      // console.log(this.portfolioPositions[1].positionId);
      // for(var i = 0; i<=max - 1; i++){
      // console.log("testing")
      // this.portfolioPositions[i].positionId = this.nameVals[i];
      // }
    })
  }


}
